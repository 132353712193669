<template>
    <div id="face-recognition-service">
        <div class="vx-row">
            <div class="vx-card p-8 ml-4 mr-4 text-center m-auto">
                <div class="w-full">
                    <span class="font-bold" style="font-size:18px">{{ $t('faceRecignition.intro') }}</span>
                </div>
                <div class="w-full mt-6">
                <span class="font-bold" style="font-size:16px">
                    {{ $t('faceRecignition.title') }}
                </span>
                </div>
                <div class="vx-row mt-2 p-6">
                    <div class="vx-col md:w-1/3 w-full mb-8 mt-8" v-for="(card, indexCard) in  listCard" :key="indexCard">
                        <div class="component w-full">
                            <img :src="card.icon" alt="" width="50" height="100">
                        </div>
                        <div class="component w-full container">
                            <span>{{ card.title }}</span>
                        </div>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="w-full m-">
                        <vs-button @click="$router.push('/user/add-face-recognition')">{{ $t('faceRecignition.setup') }}</vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            listCard: [
                {icon: require('@/assets/images/pages/pie-chart.svg'), title: this.$t('faceRecignition.feature_1')},
                {icon: require('@/assets/images/pages/contact.svg'), title: this.$t('faceRecignition.feature_2')},
                {icon: require('@/assets/images/pages/calendar-time.svg'), title: this.$t('faceRecignition.feature_3')},
                {icon: require('@/assets/images/pages/searchass-svgrepo-com.svg'), title: this.$t('faceRecignition.feature_4')},
                {icon: require('@/assets/images/pages/code-svg.svg'), title: this.$t('faceRecignition.feature_5')},
                {icon: require('@/assets/images/pages/email-svgrepo-com.svg'), title: this.$t('faceRecignition.featurer_6')},
            ]
        }
    }
}
</script>
