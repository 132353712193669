<template>
    <div id="face-recognition">
        <FaceRecognition v-show="faceRecognitionList && faceRecognitionList.length == 0"/>
        <FaceRecognitionInformation v-show="faceRecognitionList && faceRecognitionList.length > 0"/>
    </div>
</template>

<script>
import FaceRecognition from './FaceRecognitionService';
import FaceRecognitionInformation from "./FaceRecognitionInformation"
export default {
    components: {
        FaceRecognition,
        FaceRecognitionInformation
    },
    data() {
        return {
            faceRecognitionList: null
        }
    },
    created() {
        this.getFaceRecognition();
    },
    methods: {
        getFaceRecognition: function(){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let organizationBranchId = userInfo.organizationBranchId ? userInfo.organizationBranchId : null
            this.$crm.get(`/face-recognition/find-all${organizationBranchId
                ? '?organizationBranchId=' + organizationBranchId : ''}`).then((res) => {
                this.faceRecognitionList = res.data;
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
    },
}
</script>

