<template>
    <div id="face-recognition-information">
        <div class="vx-card p-6">
            <div class="vx-row items-center mb-2">
                <div class="vx-col md:w-1/2">
                    <TitleScreen></TitleScreen>
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-button
                        class="float-right ml-5"
                        @click="addFaceRecognition"
                        icon="icon-plus"
                        icon-pack="feather"
                        color="primary">
                        {{ $t('button.addNew') }}
                    </vs-button>
                </div>
            </div>
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/4 sm:w-auto">
                    <label class="vs-input--label">{{$t('menuIntrusion.branch')}}</label>
                    <v-select class="" v-model="selectedOrganizationBranch"
                              :options="organizationBranchOptions">
                        <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-3/4 float-right">
                    <vs-input class="md:w-1/4 float-right"
                              v-model="searchQuery"
                              @input="updateSearchQuery" :placeholder="$t('button.search')"/>
                </div>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue
                ref="agGridTable"
                :components="components"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="faceRecognition"
                :animateRows="true"
                :pagination="true"
                :paginationPageSize="paginationPageSize"
                :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                :onGridReady="onGridReady"
                :suppressPaginationPanel="true">
            </ag-grid-vue>

            <div class="vx-row items-center">
                <div class="vx-col md:w-1/2">
                    <Pagination
                        :itemList="faceRecognition"
                        :currentPage="currentPage"
                        :paginationPageSize="paginationPageSize"
                        :gridApi="gridApi"
                    />
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-pagination
                        :total="totalPages"
                        :max="maxPageNumbers"
                        v-model="currentPage"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererManagerActions from "./cell-renderer/CellRendererManagerActions.vue"
import Pagination from "../../ui-elements/Pagination.vue"
import moment from "moment";
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    name: 'FaceRecognition',
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererManagerActions,
        Pagination,
        TitleScreen
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuFaceRecognition.eventName'),
                    field: 'name',
                    width: 120
                },
                {
                    headerName: this.$t('menuCamera.organizationBranch') ,
                    field: 'organizationBranchName',
                    width: 120
                },
                {
                    headerName: 'Camera',
                    field: 'cameraName',
                    width: 120
                },
                {
                    headerName: this.$t('menuCustomer.dateCreated'),
                    field: 'createdAt',
                    width: 100,
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 60,
                    cellRendererFramework: 'CellRendererManagerActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererManagerActions,
            },
            faceRecognition: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            cameraOptions: []
        }
    },
    async created() {
        await this.getCamera();
        this.initScreen()
        this.$eventBus.$on('i18n', (event) => {
            this.columnDefs[1].headerName = this.$t('menuFaceRecognition.eventName');
            this.columnDefs[3].headerName = this.$t('button.actions');
            this.gridOptions.api.setColumnDefs(this.columnDefs);
        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateFaceRecognition'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_FACE_RECOGNITION', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj ? obj.value : null;
                this.getFaceRecognition()
            }
        }
    },
    methods: {
        getCamera() {
            return new Promise(resolve => {
                let url = '/camera/all';
                this.$crm.post(url).then((response) => {
                    if (response.data.length > 0) {
                        this.cameraOptions = response.data;
                    }
                    resolve(true);
                }).catch((err) => {
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        addFaceRecognition() {
            this.$router.push('/user/add-face-recognition');
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('textFaceRecognition.undefined');
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }
            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            this.organizationBranchOptions.unshift({value: null, label: this.$t('menuIntrusion.all')})
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
        },
        getFaceRecognition() {
            this.$vs.loading();
            this.$crm.get(`/face-recognition/find-all${this.selectedOrganizationBranchValue
                ? '?organizationBranchId=' + this.selectedOrganizationBranchValue : ''}`).then((response) => {
                response.data.map(x => {
                    let camera = this.cameraOptions.find(cam => cam.id == x.cameraId);
                    if (camera) x.cameraName = camera.cameraName;
                    let org = this.organizationBranchOptions.find(org => x.organizationBranchId == org.value);
                    if (org) x.organizationBranchName = org.label;
                    x.createdAt = moment(x.createdAt).format('DD/MM/YYYY');
                    return x;
                })
                this.faceRecognition = response.data;
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
                this.$vs.loading.close();
            }).catch(() => {
                this.$vs.loading.close();
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>
